import axios from 'axios'
import store from '../store'
import { useToast } from "vue-toastification"

let toast = useToast()

export function responseInterceptor() {
    axios.interceptors.response.use((response) => {
        if(response.config.method != "get") {
            toast.success(response.data.message)
        }
        return response
    }, (error) => {

        const { response } = error

        for(const error in response.data.errors) {
            toast.error(response.data.errors[error][0])
        };

        if (!response) {
            console.error(error)
            return;
        }
    
        if ([401, 403].includes(response.status) && store.state.logged) {
            store.commit('logOut')
            window.location.href = "/"
        }
    });
}