<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2>Centros</h2>
            </div>
            <div class="col-md-2 mt-2">
              <button class="btn btn-light btn-block" data-toggle="modal" data-target="#altaModal">
                Dar de alta
              </button>
            </div>
          </div>

          <div class="modal" id="altaModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5>Datos del Centro</h5>
                  <form id="altaForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input type="text" name="name" id="name" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="address">Dirección</label>
                        <input type="text" name="address" id="address" class="form-control" required />
                      </div>
                      <div class="form-group col-3">
                        <label for="island">Isla</label>
                        <multiselect v-model="selectedIsland" 
                        :options="islands"
                        :show-no-options="false"
                          tag-placeholder=""
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder=""
                          label="name"
                          required
                        
                        ></multiselect>
                      </div>
                      <div class="form-group col-3">
                        <label for="business">Empresa</label>
                        <input type="text" name="business" id="business" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="code">Código</label>
                        <input type="text" name="code" id="code" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="phone">Teléfono</label>
                        <input type="tel" name="phone" id="phone" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="password">Contraseña</label>
                        <input type="text" name="password" id="password" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="CCC">CCC</label>
                        <input type="text" name="CCC" id="CCC" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="CIF">CIF</label>
                        <input type="text" name="CIF" id="CIF" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="alta">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="centers" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" tabindex="-1" id="editarModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar Centro</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <h5>Datos del Centro {{ itemTarget.id }}</h5>
                  <form id="editarForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input :value="itemTarget.name" type="text" name="name" id="name_editar" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="address">Dirección</label>
                        <input :value="itemTarget.address" type="text" name="address" id="address_editar" class="form-control" required />
                      </div>
                      <div class="form-group col-3">
                        <label for="island">Isla</label>
                        <multiselect v-model="this.selectedIsland" 
                        :options="islands"
                        :show-no-options="false"
                          tag-placeholder=""
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          label="name"
                          placeholder=""></multiselect>
                      </div>
                      <div class="form-group col-3">
                        <label for="business">Empresa</label>
                        <input :value="itemTarget.business" type="text" name="business" id="business_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="code">Código</label>
                        <input :value="itemTarget.code" type="text" name="code" id="code_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="phone">Teléfono</label>
                        <input :value="itemTarget.phone" type="tel" name="phone" id="phone_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="email">Email</label>
                        <input :value="itemTarget.email" type="email" name="email" id="email_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="password">Contraseña</label>
                        <input :value="itemTarget.password" type="text" name="password" id="password_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="CCC">CCC</label>
                        <input :value="itemTarget.CCC" type="text" name="CCC" id="CCC_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="CIF">CIF</label>
                        <input :value="itemTarget.CIF" type="text" name="CIF" id="CIF_editar" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" id="editarButton"
                    @click="actualizar($event, itemTarget, 'boton')">
                    Actualizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente un Centro almacenado.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminar(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
  import Multiselect from 'vue-multiselect'
  export default {
    components: { Multiselect },
    data() {
      return {
        centers: [],
        selectedIsland: null,
        islands: [],
        itemTarget: {
          id: null,
          name: null,
          address: null,
          island_id: null,
          business: null,
          code: null,
          phone: null,
          email: null,
          password: null,
          CCC: null,
          CIF: null,
          island: null,
        },
        cabecerasTabla: [{
            public: "Nombre",
            private: "name",
            column: 1
          },
          {
            public: "Dirección",
            private: "address",
            column: 2
          },
          {
            public: "Isla",
            private: "island",
            column: 3
          },
          {
            public: "Negocio",
            private: "business",
            column: 4
          },
          {
            public: "Teléfono",
            private: "phone",
            column: 5
          },
          {
            public: "CIF",
            private: "CIF",
            column: 6
          }
        ]
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          if(this.selectedIsland){
            campos["island_id"] = this.selectedIsland.id
          } else {
            campos["island_id"] = null
          }
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/centers/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async eliminar(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/centers/${id}`);
          this.fillTable()
          $("#confirmarEliminarModal").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async alta() {
        const altaForm = $("#altaForm").serializeArray();
        let campos = {};
        altaForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        if (this.selectedIsland) campos["island_id"] = this.selectedIsland.id
        let response = await axios.post(
          `${process.env.VUE_APP_API_URL}/api/centers`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
      },
      async launchModal(id, tipo) {
        this.itemTarget = this.centers.find(x => x.id == id)
        this.selectedIsland = this.itemTarget.island;
        
        if (tipo == "editar") $("#editarModal").modal("show");
        if (tipo == "eliminar") $("#confirmarEliminarModal").modal("show");
      },
      async getData() {
        await this.getCenters()
        this.getIslands()
        
        
      },
      async getCenters() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/centers`)
        this.centers = response.data.data
        this.centers.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>
                    
                  </div>`
        });
      },
      async getIslands() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/islands`)
        this.islands = response.data.data       
      },
      inicializateDataTable() {
        $(function () {
          $("#centers .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let tabla = $("#centers").DataTable({
            paging: true,
            columns: [
              {
                data: "name"
              },
              {
                data: "address",
              },
              {
                data: "island.name",
              },
              {
                data: "business",
              },
              {
                data: "phone",
              },
              {
                data: "CIF",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [6],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#centers thead").on("keyup", ".column_search", function () {
            tabla.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#centers").hasClass("collapsed")) {
            $("#centers .topnav").addClass("d-none");
          } else {
            $("#centers .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#centers").hasClass("collapsed")) {
              $("#centers .topnav").addClass("d-none");
            } else {
              $("#centers .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#centers").DataTable().clear();
        $("#centers").DataTable().rows.add(this.centers);
        $("#centers").DataTable().draw();

        $("#centers").off("click", ".btnBorrar");
        $("#centers").off("click", ".btnEditar");

        $("#centers").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "eliminar")
        });
        $("#centers").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "editar")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable()
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.multiselect__option--highlight {
  background-color: #0d6efd !important;
}
</style>