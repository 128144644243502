import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'
import Toast from "vue-toastification"

import { jwtInterceptor } from './_helpers/jwt.interceptor';
import { responseInterceptor } from './_helpers/response.interceptor' 

jwtInterceptor();
responseInterceptor();

import "../node_modules/admin-lte/plugins/jquery/jquery.min.js"
import "../node_modules/admin-lte/plugins/bootstrap/js/bootstrap.bundle.min.js"
import "../node_modules/admin-lte/dist/js/adminlte.min.js"
import "../node_modules/admin-lte/plugins/datatables/jquery.dataTables.min.js"
import "../node_modules/admin-lte/plugins/datatables-bs4/js/dataTables.bootstrap4.min.js"
import "../node_modules/admin-lte/plugins/datatables-responsive/js/dataTables.responsive.min.js"
import "../node_modules/admin-lte/plugins/datatables-responsive/js/responsive.bootstrap4.min.js"
import "./_helpers/accent-neutralise.js"
import "./_helpers/natural.js"
import "../node_modules/bootstrap-switch-button/dist/bootstrap-switch-button.min.js"

import "../node_modules/admin-lte/plugins/fontawesome-free/css/all.min.css"
import '../node_modules/admin-lte/dist/css/adminlte.min.css'
import '../node_modules/admin-lte/plugins/datatables-bs4/css/dataTables.bootstrap4.min.css'
import '../node_modules/admin-lte/plugins/datatables-responsive/css/responsive.bootstrap4.min.css'
import "vue-toastification/dist/index.css";
import "../node_modules/bootstrap-switch-button/css/bootstrap-switch-button.min.css"

createApp(App).use(store).use(router).use(Toast).mount('#app')
