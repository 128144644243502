import { createWebHistory, createRouter } from 'vue-router'
import Login from '../views/Login.vue'
import Crm from '../views/crm/Crm.vue'
import Home from '../views/crm/Home.vue'
import Center from '../views/crm/Center.vue'
import Workers from '../views/crm/Workers.vue'
import Persons from '../views/crm/Persons.vue'
import Courses from '../views/crm/Courses.vue'
import FormativeAction from '../views/crm/FormativeAction.vue'
import FormativeActions from '../views/crm/FormativeActions.vue'
import Recruit from '../views/crm/Recruit.vue'
import Classroom from '../views/crm/Classroom.vue'
import store from '../store'

const routes = [
    {
        path: '',
        component: Login,
        beforeEnter(to, from, next) {
            if (store.state.logged) {
                next({
                    path: '/crm',
                    replace: true
                })
            } else {
                next()
            }
        }
    },
    {
        path: '/crm',
        component: Crm,
        beforeEnter(to, from, next) {
            if (!store.state.logged) {
                next({
                    path: '/',
                    replace: true
                })
            } else {
                next()
            }
        },
        children: [
            {
                path: '',
                component: Home
            },
            {
                path: 'centers',
                component: Center,
            },
            {
                path: 'workers',
                component: Workers,
            },
            {
                path: 'persons/:type',
                component: Persons,
            },
            {
                path: 'recruits',
                component: Recruit
            },
            {
                path: 'classrooms',
                component: Classroom
            },
            {
                path: 'formativeActions/:entity',
                component: FormativeActions
            },
            {
                path: 'formativeAction/:course',
                component: FormativeAction
            },
            {
                path: 'courses',
                component: Courses
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router