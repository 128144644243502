<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2 v-if="this.$route.params.type === 'students'">Alumnos</h2>
              <h2 v-else-if="this.$route.params.type === 'teachers'">Docentes</h2>
            </div>
            <div class="col-md-2 mt-2">
              <button class="btn btn-light btn-block" data-toggle="modal" data-target="#altaModal">
                Dar de alta
              </button>
            </div>
          </div>

          <div class="modal" id="altaModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5 v-if="this.$route.params.type === 'students'">Datos del Alumno</h5>
                  <h5 v-else-if="this.$route.params.type === 'teachers'">Datos del Docente</h5>
                  <form id="altaForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input type="text" name="name" id="name" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="surname">Apellidos</label>
                        <input type="text" name="surname" id="surname" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="gender">Genero</label>
                        <select v-model="selectedGender" class="form-control">
                          <option value="0">Hombre</option>
                          <option value="1">Mujer</option>
                        </select>
                      </div>
                      <div class="form-group col-3">
                        <label for="disability">Discapacidad</label>
                        <input type="text" name="disability" id="disability" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="birth_date">Fecha de nacimiento</label>
                        <input type="date" name="birth_date" id="birth_date" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="fixed_phone">Teléfono</label>
                        <input type="number" name="fixed_phone" id="fixed_phone" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="mobile_phone">Teléfono móvil</label>
                        <input type="number" name="mobile_phone" id="mobile_phone" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="postal_code">Código postal</label>
                        <input type="number" name="postal_code" id="postal_code" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="island">Isla</label>
                        <multiselect v-model="selectedIsland" 
                          :options="islands" 
                          :show-no-options="false"
                          tag-placeholder="" 
                          :hide-selected="false" 
                          :show-no-results="false"
                          selectedLabel="Seleccionado" 
                          selectLabel="Seleccionar" 
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true" 
                          :searchable="true" 
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder="" 
                          label="name">
                        </multiselect>
                      </div>
                      <div class="form-group col-3">
                        <label for="town">Municipio</label>
                        <select v-model="selectedTown" class="form-control">
                          <option v-for="town in filteredTowns" v-bind:value="town.id">{{town.name}}</option>
                        </select>
                      </div>
                      <div class="form-group col-6">
                        <label for="address">Dirección</label>
                        <input type="text" name="address" id="address" class="form-control" />
                      </div>
                      <div v-if="this.$route.params.type === 'students'" class="form-group col-6">
                        <label for="situation">Situacion</label>
                        <input type="text" name="situation" id="situation" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="social_security_number">Número de la seguridad social</label>
                        <input type="text" name="social_security_number" id="social_security_number" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="alta">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="persons" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" tabindex="-1" id="editarModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar Aula</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <h5>Datos de {{ itemTarget.name }} {{ itemTarget.surname }}</h5>
                  <form id="editarForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input :value="itemTarget.name" type="text" name="name" id="name_edit" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="surname">Apellidos</label>
                        <input :value="itemTarget.surname" type="text" name="surname" id="surname_edit" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="email">Email</label>
                        <input :value="itemTarget.email" type="email" name="email" id="email" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="gender">Genero</label>
                        <select v-model="selectedGender" class="form-control">
                          <option value="0">Hombre</option>
                          <option value="1">Mujer</option>
                        </select>
                      </div>
                      <div class="form-group col-3">
                        <label for="disability">Discapacidad</label>
                        <input :value="itemTarget.disability" type="text" name="disability" id="disability_edit" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="birth_date">Fecha de nacimiento</label>
                        <input :value="itemTarget.birth_date" type="date" name="birth_date" id="birth_date_edit" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="fixed_phone">Teléfono</label>
                        <input :value="itemTarget.fixed_phone" type="number" name="fixed_phone" id="fixed_phone_edit" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="mobile_phone">Teléfono móvil</label>
                        <input :value="itemTarget.mobile_phone" type="number" name="mobile_phone" id="mobile_phone_edit" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="postal_code">Código postal</label>
                        <input :value="itemTarget.postal_code" type="number" name="postal_code" id="postal_code_edit" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="island">Isla</label>
                        <multiselect v-model="this.selectedIsland" 
                          :options="islands" 
                          :show-no-options="false"
                          tag-placeholder="" 
                          :hide-selected="false" 
                          :show-no-results="false"
                          selectedLabel="Seleccionado" 
                          selectLabel="Seleccionar" 
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true" 
                          :searchable="true" 
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder="" 
                          label="name">
                        </multiselect>
                      </div>
                      <div class="form-group col-3">
                        <label for="town">Municipio</label>
                        <select class="form-control" name="town_id">
                          <option v-for="town in filteredTowns" :value="town.id" :selected="town.id == itemTarget.town_id">{{town.name}}</option>
                        </select>
                      </div>
                      <div class="form-group col-6">
                        <label for="address">Dirección</label>
                        <input :value="itemTarget.address" type="text" name="address" id="address_edit" class="form-control" />
                      </div>
                      <div v-if="this.$route.params.type === 'students'" class="form-group col-6">
                        <label for="situation">Situacion</label>
                        <input :value="itemTarget.situation" type="text" name="situation" id="situation_edit" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="social_security_number">Número de la seguridad social</label>
                        <input :value="itemTarget.social_security_number" type="text" name="social_security_number" id="social_security_number_edit" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" id="editarButton"
                    @click="actualizar($event, itemTarget, 'boton')">
                    Actualizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente una Aula almacenada.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminar(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
  import Multiselect from 'vue-multiselect';
  export default {
    components: {
      Multiselect
    },
    data() {
      return {
        type: null,
        personType: null,
        persons: [],
        selectedIsland: null,
        selectedTown: null,
        selectedGender: null,
        islands: [],
        towns: [],
        storage: JSON.parse(localStorage.getItem("vuex")),
        itemTarget: {
          id: null,
          name: null,
          surname: null,
          email: null,
          gender: null,
          disability: null,
          fixed_phone: null,
          mobile_phone: null,
          postal_code: null,
          birth_date: null,
          level: null,
          social_security_number: null,
          contact: null,
          dni: null,
          island_id: null,
          town_id: null
        },
        cabecerasTabla: [{
            public: "Nombre",
            private: "name",
            column: 1
          },
          {
            public: "Apellidos",
            private: "surname",
            column: 2
          },
          {
            public: "Email",
            private: "email",
            column: 3
          },
          {
            public: "DNI",
            private: "dni",
            column: 4
          },
          {
            public: "Isla",
            private: "island_id",
            column: 5
          },
          {
            public: "Municipio",
            private: "town_id",
            column: 5
          }
        ]
      }
    },
    computed: {
      filteredTowns: function() {
        if(this.selectedIsland == null) return this.towns;
        return this.towns.filter(function(el) {
          return el.island_id === this.selectedIsland.id;
        }, this);
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          if (this.selectedIsland) campos["island_id"] = this.selectedIsland.id
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/persons/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async eliminar(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/persons?type=${this.type}/${id}`);
          this.fillTable()
          $("#confirmarEliminarModal").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async alta() {
        const altaForm = $("#altaForm").serializeArray();
        let campos = {};
        altaForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        campos["user_id"] = this.storage.userId;
        if (this.selectedIsland) campos["island_id"] = this.selectedIsland.id
        if (this.selectedTown) campos["town_id"] = this.selectedTown
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/persons?type=${this.type}`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
      },
      async launchModal(id, tipo) {
        this.itemTarget = this.persons.find(x => x.id == id)
        this.selectedIsland = this.itemTarget.island;
        this.selectedTown = this.itemTarget.town;

        if (tipo == "editar") $("#editarModal").modal("show");
        if (tipo == "eliminar") $("#confirmarEliminarModal").modal("show");
      },
      async getData() {
        this.type = this.$route.params.type
        await this.getPersons()
        this.getIslands()
        this.getTowns()
      },
      async getPersons() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/persons?type=${this.type}`)
        this.persons = response.data.data
        this.persons.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
        });
      },
      async getIslands() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/islands`)
        this.islands = response.data.data
      },
      async getTowns() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/towns`)
        this.towns = response.data.data
      },
      inicializateDataTable() {
        $(function () {
          $("#persons .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let tabla = $("#persons").DataTable({
            paging: true,
            columns: [{
                data: "name",
              },
              {
                data: "surname",
              },
              {
                data: "email",
              },
              {
                data: "dni",
              },
              {
                data: "island.name",
              },
              {
                data: "town.name",
              },
              {
                data: "accion",
              },
            ],
            columnDefs: [{
              orderable: false,
              targets: [6],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#persons thead").on("keyup", ".column_search", function () {
            tabla.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#persons").hasClass("collapsed")) {
            $("#persons .topnav").addClass("d-none");
          } else {
            $("#persons .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#persons").hasClass("collapsed")) {
              $("#persons .topnav").addClass("d-none");
            } else {
              $("#persons .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#persons").DataTable().clear();
        $("#persons").DataTable().rows.add(this.persons);
        $("#persons").DataTable().draw();

        $("#persons").off("click", ".btnBorrar");
        $("#persons").off("click", ".btnEditar");

        $("#persons").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "eliminar")
        });
        $("#persons").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "editar")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable();

      this.$watch(
          () => this.$route.params,
          () => {
              this.fillTable()
          }, {
              immediate: true
          }
      )
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
  .multiselect__option--highlight {
    background-color: #0d6efd !important;
  }
</style>