<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2>Acción Formativa: {{ this.courseName }}</h2>
            </div>
            <div class="col-md-2 mt-2 row" id="indexButtons">
              <div class="col-6">
                <button class="btn btnStudents btn-light btn-block">
                  Añadir alumnos
                </button>
              </div>
              <div class="col-6">
                <button class="btn btnTeachers btn-light btn-block">
                  Añadir docentes
                </button>
              </div>
            </div>
          </div>

          <div class="modal" id="addStudents" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta alumnos</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <table id="studentsList" class="table table-bordered table-striped">
                        <thead>
                          <tr class="topnav">
                            <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                            <th>Acción</th>
                          </tr>
                          <tr>
                            <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                              {{ cabecera.public }}
                            </th>
                            <th>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="col-12">
                      <form id="addStudentsForm">
                        <table id="addStudentsList" class="table table-bordered table-striped">
                          <thead>
                            <tr class="topnav">
                              <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                              <th>Acción</th>
                            </tr>
                            <tr>
                              <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                                {{ cabecera.public }}
                              </th>
                              <th>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="addStudents">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="addTeachers" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta docentes</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-12">
                      <table id="teachersList" class="table table-bordered table-striped">
                        <thead>
                          <tr class="topnav">
                            <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                            <th>Acción</th>
                          </tr>
                          <tr>
                            <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                              {{ cabecera.public }}
                            </th>
                            <th>
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                    <div class="col-12">
                      <form id="addTeachersForm">
                        <table id="addTeachersList" class="table table-bordered table-striped">
                          <thead>
                            <tr class="topnav">
                              <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                              <th>Acción</th>
                            </tr>
                            <tr>
                              <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                                {{ cabecera.public }}
                              </th>
                              <th>
                              </th>
                            </tr>
                          </thead>
                        </table>
                      </form>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="addTeachers">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <h2>Alumnos</h2>
          <table id="students" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <h2>Docentes</h2>
          <table id="teachers" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" id="deleteStudent" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente el alumno almacenado.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="deleteStudent(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="deleteTeacher" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente el docente almacenado.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="deleteTeacher(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
   export default {
    data() {
      return {
        storage: JSON.parse(localStorage.getItem("vuex")),
        course: null,
        courseName: null,
        students: [],
        studentsList: [],
        allStudentsList: [],
        studentsAddList: null,
        studentsAddEntryList: null,
        addedStudents: null,
        teachers: [],
        teachersList: [],
        allTeachersList: [],
        teachersAddList: null,
        teachersAddEntryList: null,
        addedTeachers: null,
        itemTarget: {
          name: null,
          email: null,
          phone: null
        },
        cabecerasTabla: [{
            public: "Nombre",
            private: "name",
            column: 1
          },
          {
            public: "Email",
            private: "email",
            column: 2
          },
          {
            public: "Teléfono",
            private: "phone",
            column: 3
          },
        ]
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/course-students/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async deleteStudent(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/course-students/${id}`);
          this.fillTable()
          $("#deleteStudent").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async deleteTeacher(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/course-teachers/${id}`);
          this.fillTable()
          $("#deleteTeacher").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async addStudents() {
        let esto = this
        let courseId = this.$route.params.course
        let values = [];
        let students = esto.studentsAddList.split(",");
        let entry = esto.studentsAddEntryList.split(",");
        students.forEach(function(student, index) {
          values.push({"person_id": student, "entry": entry[index]});
        });
        esto.studentsAddList = null,
        esto.studentsAddEntryList = null,
        esto.addedStudents = null,

        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/course-students`,
          {'students':values, 'user_id':this.storage.userId, 'relation_center_course_id':courseId}
        );
        this.fillTable()
        $("#addStudents").modal("hide");
      },
      async addTeachers() {
        let esto = this
        let courseId = this.$route.params.course
        let values = [];
        let teachers = esto.teachersAddList.split(",");
        let entry = esto.teachersAddEntryList.split(",");
        teachers.forEach(function(teacher, index) {
          values.push({"person_id": teacher, "entry": entry[index]});
        });
        esto.teachersAddList = null,
        esto.teachersAddEntryList = null,
        esto.addedTeachers = null,
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/course-teachers`,
          {'teachers':values, 'user_id':this.storage.userId, 'relation_center_course_id':courseId}
        );
        this.fillTable()
        $("#addteachers").modal("hide");
      },
      async launchAddModal(type) {
        let esto = this
        if (type == "students"){
          await this.getStudents()
          esto.renderStudents()
         
          $("#addStudents").modal("show");
        } 
        if (type == "teachers") {
          await this.getTeachers()
          esto.renderTeachers()

          $("#addTeachers").modal("show");
        } 
      },
      async launchModal(id, type, target) {
        if(target == "student"){
          this.itemTarget = this.students.find(x => x.id == id)
          if (type == "edit") $("#editStudent").modal("show");
          if (type == "delete") $("#deleteStudent").modal("show");
        }
        if(target == "teacher"){
          this.itemTarget = this.teachers.find(x => x.id == id)
          if (type == "edit") $("#editTeacher").modal("show");
          if (type == "delete") $("#deleteTeacher").modal("show");
        }
      },
      async renderStudents(){
        let esto = this
        $("#studentsList").DataTable().clear();
        $("#studentsList").DataTable().rows.add(esto.allStudentsList);
        $("#studentsList").DataTable().draw();

        $("#studentsList").on("change", ".selectEntry", function () {
          if(esto.studentsAddEntryList == null){
            esto.studentsAddEntryList = $(this).val()
            esto.studentsAddList = $(this).attr('id')
          } else {
            let status = 'Not exist';
            let students = esto.studentsAddList.split(",");
            for(let i=0; i<students.length; i++){
              let student = students[i];
              if(student == $(this).attr('id')){
                status = 'Exist';
                break;
              }
            }
            if(status == 'Not exist'){
              esto.studentsAddEntryList = esto.studentsAddEntryList+','+$(this).val()
              esto.studentsAddList = esto.studentsAddList+','+$(this).attr('id')
            }
          }
          esto.getStudents()
        });

        if(esto.studentsAddList != null){
          const response3 = await axios.get(`${process.env.VUE_APP_API_URL}/api/students?add=${esto.studentsAddList}`)
          esto.addedStudents = response3.data.data
          let entry = esto.studentsAddEntryList.split(",");
          esto.addedStudents.forEach((item, index) => {
            let studentEntry = null;
            switch (entry[index]) {
              case '0':
                studentEntry = 'Presencial'
                break;
              case '1':
                studentEntry = 'Teleformacion'
                break;
              case '2':
                studentEntry = 'Mixta'
                break;
              case '3':
                studentEntry = 'Bimodal'
                break;
            }
            item.accion = `<div class=" d-flex justify-content-center">
                      <h4>${studentEntry}</h4>
                      <button id="${item.person_id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
          });

          $("#addStudentsList").DataTable().clear();
          $("#addStudentsList").DataTable().rows.add(esto.addedStudents);
          $("#addStudentsList").DataTable().draw();

          $("#addStudentsList").off("click", ".btnBorrar");

          $("#addStudentsList").on("click", ".btnBorrar", function () {
            let students = esto.studentsAddList.split(",");
            let entry = esto.studentsAddEntryList.split(",");
            for(let i=0; i<students.length; i++){
              let student = students[i];
              if(student == $(this).attr('id')){
                delete students[i];
                delete entry[i];
                break;
              }
            }
            esto.studentsAddList = students;
            esto.studentsAddEntryList = entry;
            esto.getStudents()
          });
        }
      },
      async renderTeachers(){
        let esto = this
        $("#teachersList").DataTable().clear();
        $("#teachersList").DataTable().rows.add(esto.allTeachersList);
        $("#teachersList").DataTable().draw();

        $("#teachersList").on("change", ".selectEntry", function () {
          if(esto.teachersAddEntryList == null){
            esto.teachersAddEntryList = $(this).val()
            esto.teachersAddList = $(this).attr('id')
          } else {
            let status = 'Not exist';
            let teachers = esto.teachersAddList.split(",");
            for(let i=0; i<teachers.length; i++){
              let teacher = teachers[i];
              if(teacher == $(this).attr('id')){
                status = 'Exist';
                break;
              }
            }
            if(status == 'Not exist'){
              esto.teachersAddEntryList = esto.teachersAddEntryList+','+$(this).val()
              esto.teachersAddList = esto.teachersAddList+','+$(this).attr('id')
            }
          }
          esto.getTeachers()
        });

        if(esto.teachersAddList != null){
          const response3 = await axios.get(`${process.env.VUE_APP_API_URL}/api/teachers?add=${esto.teachersAddList}`)
          esto.addedTeachers = response3.data.data
          let entry = esto.teachersAddEntryList.split(",");
          esto.addedTeachers.forEach((item, index) => {
            let teacherEntry = null;
            switch (entry[index]) {
              case '0':
                teacherEntry = 'Presencial'
                break;
              case '1':
                teacherEntry = 'Teleformacion'
                break;
              case '2':
                teacherEntry = 'Mixta'
                break;
              case '3':
                teacherEntry = 'Bimodal'
                break;
            }
            item.accion = `<div class=" d-flex justify-content-center">
                      <h4>${teacherEntry}</h4>
                      <button id="${item.person_id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
          });

          $("#addTeachersList").DataTable().clear();
          $("#addTeachersList").DataTable().rows.add(esto.addedTeachers);
          $("#addTeachersList").DataTable().draw();

          $("#addTeachersList").off("click", ".btnBorrar");

          $("#addTeachersList").on("click", ".btnBorrar", function () {
            let teachers = esto.teachersAddList.split(",");
            let entry = esto.teachersAddEntryList.split(",");
            for(let i=0; i<teachers.length; i++){
              let teacher = teachers[i];
              if(teacher == $(this).attr('id')){
                delete teachers[i];
                delete entry[i];
                break;
              }
            }
            esto.teachersAddList = entry;
            esto.teachersAddEntryList = entry;
            esto.getTeachers()
          });
        }
      },
      async getData() {
        await this.getCourse()
        await this.getCourseStudents()
        await this.getCourseTeachers()
      },
      async getCourse() {
        let courseId = this.$route.params.course
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/relation-center-courses/${courseId}`)
        this.course = response.data.data
        this.courseName = this.course.course_code+" "+this.course.course.name
      },
      async getCourseStudents() {
        let courseId = this.$route.params.course
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/course-students?course=${courseId}`)
        this.students = response.data.data
        const response2 = await axios.get(`${process.env.VUE_APP_API_URL}/api/course-students?list=${courseId}`)
        this.studentsList = Object.entries(response2.data.data).map((arr) => (
          arr[1]['person_id']
        ));
        this.students.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
          // item.accion = `<div class=" d-flex justify-content-center">
          //             <button id="${item.id}"
          //               class="btn btnEditar mr-2 bg-gradient-secondary"
          //             >
          //               <i class="fas fa-pen fa-fw"></i>
          //             </button>
          //             <button id="${item.id}"
          //               class="btn btnBorrar bg-gradient-secondary"
          //             >
          //               <i class="fas fa-times fa-fw"></i>
          //             </button>        
          //         </div>`
        });
      },
      async getStudents() {
        let studentsListData = null
        if(this.studentsAddList == null) {studentsListData = this.studentsList}
        else {studentsListData = this.studentsList+','+this.studentsAddList}
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/students?list=${studentsListData}`)
        this.allStudentsList = response.data.data
        this.allStudentsList.forEach(item => {
          item.accion = `<div class="d-flex justify-content-center">
                      <select class="form-control selectEntry" name="entry_status" id="${item.person_id}">
                          <option value="" selected disabled>Selecciona</option>
                          <option value="0">Presencial</option>
                          <option value="1">Teleformación</option>
                          <option value="2">Mixta</option>
                          <option value="3">BiModal</option>
                      </select>      
                  </div>`
        });

        let esto = this
        if(esto.studentsAddList != null){ esto.renderStudents() }
      },
      async getCourseTeachers() {
        let courseId = this.$route.params.course
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/course-teachers?course=${courseId}`)
        this.teachers = response.data.data
        const response2 = await axios.get(`${process.env.VUE_APP_API_URL}/api/course-teachers?list=${courseId}`)
        this.teachersList = Object.entries(response2.data.data).map((arr) => (
          arr[1]['person_id']
        ));
        this.teachers.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
          // item.accion = `<div class=" d-flex justify-content-center">
          //             <button id="${item.id}"
          //               class="btn btnEditar mr-2 bg-gradient-secondary"
          //             >
          //               <i class="fas fa-pen fa-fw"></i>
          //             </button>
          //             <button id="${item.id}"
          //               class="btn btnBorrar bg-gradient-secondary"
          //             >
          //               <i class="fas fa-times fa-fw"></i>
          //             </button>        
          //         </div>`
        });
      },
      async getTeachers() {
        let teachersListData = null
        if(this.teachersAddList == null) {teachersListData = this.teachersList}
        else {teachersListData = this.teachersList+','+this.teachersAddList}   

        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/teachers?list=${teachersListData}`)
        this.allTeachersList = response.data.data
        this.allTeachersList.forEach(item => {
          item.accion = `<div class="d-flex justify-content-center">
                      <select class="form-control selectEntry" name="entry_status" id="${item.person_id}">
                          <option value="" selected disabled>Selecciona</option>
                          <option value="0">Presencial</option>
                          <option value="1">Teleformación</option>
                          <option value="2">Mixta</option>
                          <option value="3">BiModal</option>
                      </select>      
                  </div>`
        });

        let esto = this
        if(esto.teachersAddList != null){ esto.renderTeachers() }
      },
      inicializateDataTable() {
        $(function () {
          $("#students .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          $("#teachers .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          $("#studentsList .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          $("#teachersList .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          $("#addStudentsList .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          $("#addteachersList .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let studentsTable = $("#students").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          let studentsListTable = $("#studentsList").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          let addStudentsListTable = $("#addStudentsList").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          let teachersTable = $("#teachers").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          let teachersListTable = $("#teachersList").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          let addteachersListTable = $("#addTeachersList").DataTable({
            paging: true,
            columns: [{
                data: "person.name",
              },
              {
                data: "person.email",
              },
              {
                data: "person.mobile_phone",
              },
              {
                data: "accion"
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [3],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#students thead").on("keyup", ".column_search", function () {
            studentsTable.column($(this).parent().index()).search(this.value).draw();
          });
          $("#studentsList thead").on("keyup", ".column_search", function () {
            studentsListTable.column($(this).parent().index()).search(this.value).draw();
          });
          $("#addStudentsList thead").on("keyup", ".column_search", function () {
            addStudentsListTable.column($(this).parent().index()).search(this.value).draw();
          });
          $("#teachers thead").on("keyup", ".column_search", function () {
            teachersTable.column($(this).parent().index()).search(this.value).draw();
          });
          $("#teachersList thead").on("keyup", ".column_search", function () {
            teachersListTable.column($(this).parent().index()).search(this.value).draw();
          });
          $("#addTeachersList thead").on("keyup", ".column_search", function () {
            addteachersListTable.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#students").hasClass("collapsed")) {
            $("#students .topnav").addClass("d-none");
          } else {
            $("#students .topnav").removeClass("d-none");
          }
          if ($("#teachers").hasClass("collapsed")) {
            $("#teachers .topnav").addClass("d-none");
          } else {
            $("#teachers .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#students").hasClass("collapsed")) {
              $("#students .topnav").addClass("d-none");
            } else {
              $("#students .topnav").removeClass("d-none");
            }
            if ($("#teachers").hasClass("collapsed")) {
              $("#teachers .topnav").addClass("d-none");
            } else {
              $("#teachers .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#indexButtons").off("click", ".btnBorrar");
        $("#indexButtons").off("click", ".btnEditar");

        $("#students").DataTable().clear();
        $("#students").DataTable().rows.add(this.students);
        $("#students").DataTable().draw();

        $("#students").off("click", ".btnBorrar");
        $("#students").off("click", ".btnEditar");

        $("#indexButtons").on("click", ".btnStudents", function () {
          esto.launchAddModal("students")
        });
        $("#indexButtons").on("click", ".btnTeachers", function () {
          esto.launchAddModal("teachers")
        });
        $("#students").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "delete", "student")
        });
        $("#students").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "edit", "student")
        });

        $("#teachers").DataTable().clear();
        $("#teachers").DataTable().rows.add(this.teachers);
        $("#teachers").DataTable().draw();

        $("#teachers").off("click", ".btnBorrar");
        $("#teachers").off("click", ".btnEditar");

        $("#teachers").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "delete", "teacher")
        });
        $("#teachers").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "edit", "student")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable()

    },
  };
</script>