<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2>Cursos</h2>
            </div>
            <div class="col-md-2 mt-2">
              <button class="btn btn-light btn-block" data-toggle="modal" data-target="#altaModal">
                Dar de alta
              </button>
            </div>
          </div>

          <div class="modal" id="altaModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    <h5>Datos del Curso</h5>
                    <form id="altaForm">
                        <div class="form-row">
                            <div class="col-xl-6 form-group">
                                <label>Nombre</label>
                                <input type="text" class="form-control" id="name" name="name"
                                    placeholder="">
                            </div>

                            <div class="col-xl-6 form-group">
                                <label>Código de especialidad</label>
                                <input type="text" class="form-control" id="specialty_code"
                                    name="specialty_code" placeholder="">
                            </div>

                            <div class="col-xl-6 form-group">
                                <label>Nivel</label>
                                <input type="text" class="form-control" id="level" name="level"
                                    placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Horas</label>
                                <input type="text" class="form-control" id="hours" name="hours"
                                    placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Imagen</label>
                                <input type="text" class="form-control" id="course_image"
                                    name="course_image" placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Descripción</label>
                                <input type="text" class="form-control" id="description"
                                    name="description" placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Familia profesional</label>
                                <input type="text" class="form-control" id="professional_family"
                                    name="professional_family" placeholder="">
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="alta">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="courses" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" tabindex="-1" id="editarModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar Registro</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                    <h5>Datos del Registro {{ itemTarget.id }}</h5>
                    <form id="editarForm">
                        <div class="form-row">
                            <div class="col-xl-6 form-group">
                                <label>Nombre</label>
                                <input :value="itemTarget.name" type="text" class="form-control" id="name" name="name"
                                    placeholder="">
                            </div>

                            <div class="col-xl-6 form-group">
                                <label>Código de especialidad</label>
                                <input :value="itemTarget.specialty_code" type="text" class="form-control" id="specialty_code"
                                    name="specialty_code" placeholder="">
                            </div>

                            <div class="col-xl-6 form-group">
                                <label>Nivel</label>
                                <input :value="itemTarget.level" type="text" class="form-control" id="level" name="level"
                                    placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Horas</label>
                                <input :value="itemTarget.hours" type="text" class="form-control" id="hours" name="hours"
                                    placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Imagen</label>
                                <input :value="itemTarget.course_image" type="text" class="form-control" id="course_image"
                                    name="course_image" placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Descripción</label>
                                <input :value="itemTarget.description" type="text" class="form-control" id="description"
                                    name="description" placeholder="">
                            </div>
                            <div class="col-xl-6 form-group">
                                <label>Familia profesional</label>
                                <input :value="itemTarget.professional_family" type="text" class="form-control" id="professional_family"
                                    name="professional_family" placeholder="">
                            </div>
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        Cancelar
                    </button>
                    <button type="button" class="btn btn-primary" id="editarButton"
                        @click="actualizar($event, itemTarget, 'boton')">
                        Actualizar
                    </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente un Registro almacenado.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminar(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
  export default {
    data() {
      return {
        courses: [],
        itemTarget: {
            id: null,
            name: null,
            specialty_code: null,
            level: null,
            hours: null,
            professional_family: null,
            accion: null,
        },
        cabecerasTabla: [
            {
                public: "Nombre",
                private: "name",
                column: 0
            },
            {
                public: "Especialidad",
                private: "speciality_code",
                column: 1
            },
            {
                public: "Nivel",
                private: "level",
                column: 2
            },
            {
                public: "Horas",
                private: "hours",
                column: 3
            },
            {
                public: "Familia",
                private: "professional_family",
                column: 4
            },
        ]
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/courses/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async eliminar(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/courses/${id}`);
          this.fillTable()
          $("#confirmarEliminarModal").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async alta() {
        const altaForm = $("#altaForm").serializeArray();
        let campos = {};
        altaForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/courses`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
      },
      async launchModal(id, tipo) {
        this.itemTarget = this.courses.find(x => x.id == id)

        if (tipo == "editar") $("#editarModal").modal("show");
        if (tipo == "eliminar") $("#confirmarEliminarModal").modal("show");
      },
      async getData() {
        await this.getCourses()
      },
      async getCourses() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/courses`)
        this.courses = response.data.data
        this.courses.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`

        });
      },
      inicializateDataTable() {
        $(function () {
          $("#courses .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let tabla = $("#courses").DataTable({
            paging: true,
            columns: [
                {
                    data: "name",
                },
                {
                    data: "specialty_code",
                },
                {
                    data: "level",
                },
                {
                    data: "hours",
                },
                {
                    data: "professional_family",
                },
                {
                    data: "accion"
                },
            ],
            columnDefs: [{
              orderable: false,
              targets: [5],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#courses thead").on("keyup", ".column_search", function () {
            tabla.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#courses").hasClass("collapsed")) {
            $("#courses .topnav").addClass("d-none");
          } else {
            $("#courses .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#courses").hasClass("collapsed")) {
              $("#courses .topnav").addClass("d-none");
            } else {
              $("#courses .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#courses").DataTable().clear();
        $("#courses").DataTable().rows.add(this.courses);
        $("#courses").DataTable().draw();

        $("#courses").off("click", ".btnBorrar");
        $("#courses").off("click", ".btnEditar");

        $("#courses").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "eliminar")
        });
        $("#courses").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "editar")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable()

    },
  };
</script>