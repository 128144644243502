<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="#" class="brand-link">
      <span class="brand-text font-weight-light ml-2">CRM</span>
    </a>

    <div class="sidebar">
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <div class="user-panel mb-3">
            <li class="nav-item menu-open">
              <a href="#" class="nav-link">
                <i class="fas fa-user-cog text-light mx-2"></i>
                <p>
                  {{ name }}
                  <i class="right fas fa-angle-left"></i>
                </p>
              </a>
              <ul class="nav nav-treeview">
                <li class="nav-item">
                  <router-link to="/" class="nav-link">
                    <i class="fas fa-key mx-2"></i>
                    <p>Cambiar contraseña</p>
                  </router-link>
                </li>
                <li class="nav-item">
                  <a @click="logout()" class="nav-link">
                    <i class="nav-icon fas fa-power-off mx-2"></i>
                    <p>Cerrar sesión</p>
                  </a>
                </li>
              </ul>
            </li>
          </div>
          <li class="nav-item">
            <router-link to="/crm" class="nav-link" :class="[getRoute == '/crm' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Inicio</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/crm/centers" class="nav-link" :class="[getRoute == '/crm/centers' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Centros</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/crm/workers" class="nav-link" :class="[getRoute == '/crm/workers' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Trabajadores</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/crm/persons/students" class="nav-link" :class="[getRoute == '/crm/persons/students' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Alumnos</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/crm/classrooms" class="nav-link"
              :class="[getRoute == '/crm/classrooms' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Aulas</p>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/crm/recruits" class="nav-link" :class="[getRoute == '/crm/recruits' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Recruits</p>
            </router-link>
          </li>
          <li class="nav-item">
            <a href="#" class="nav-link" :class="[getRoute.includes('formativeActions') ? 'active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>
                Acciones Formativas
                <i class="right fas fa-angle-left"></i>
              </p>
            </a>
            <ul class="nav nav-treeview">
              <li v-for="entity in entities" class="nav-item">
                <router-link :to="'/crm/formativeActions/'+entity.id" class="nav-link" :class="[
                    this.$route.path == '/crm/formativeActions/'+entity.id
                      ? 'active'
                      : '',
                  ]">
                  <i class="fas fa-caret-right nav-icon"></i>
                  <p>{{ entity.shortname }}</p>
                </router-link>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link to="/crm/courses" class="nav-link" :class="[getRoute == '/crm/courses' ? ' active' : '']">
              <i class="fas fa-caret-right nav-icon"></i>
              <p>Cursos</p>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </aside>
</template>

<script>
  const $ = require('jquery')
  import axios from "axios"
  import {
    mapMutations
  } from "vuex";
  import {
    mapState
  } from "vuex";
  export default {
    data() {
      return {
        name: this.$store.state.name,
        entities: [],
      };
    },
    computed: {
      ...mapState(["userId"]),
      getRoute() {
        return this.$route.path;
      },
    },
    methods: {
      ...mapMutations(["logOut"]),
      logout() {
        this.logOut();
        window.location.href = process.env.BASE_URL;
      },
    },
    async beforeCreate() {
      const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/entities`)
      this.entities = response.data.data
    }
  };
</script>