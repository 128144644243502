import { createStore } from 'vuex'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

export default createStore({
    state: {
        logged: false,
        userId: "",
        name: "",
        token: "",
    },
    plugins: [vuexLocal.plugin],
    mutations: {
        logIn(state, response) {
            state.token = response.access_token
            state.userId = response.user.id
            state.name = response.user.name
            state.logged = true
        },
        logOut(state) {
            state.userId = null
            state.name = null
            state.token = null
            state.logged = false
        }
    }
  })