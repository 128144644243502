<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2>Aulas</h2>
            </div>
            <div class="col-md-2 mt-2">
              <button class="btn btn-light btn-block" data-toggle="modal" data-target="#altaModal">
                Dar de alta
              </button>
            </div>
          </div>

          <div class="modal" id="altaModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5>Datos de la clase</h5>
                  <form id="altaForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="class_code">Código del aula</label>
                        <input type="text" name="class_code" id="class_code" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="class_name">Nombre del aula</label>
                        <input type="text" name="class_name" id="class_name" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="type">Tipo de aula</label>
                        <input type="text" name="type" id="type" class="form-control" />
                      </div>
                      <div class="form-group col-6">
                        <label for="meters">Metros cuadrados del aula</label>
                        <input type="text" name="meters" id="meters" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="center_id">Centro</label>
                        <multiselect v-model="selectedCenter" 
                        :options="centers"
                        :show-no-options="false"
                          tag-placeholder=""
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder=""
                          label="name"
                        
                        ></multiselect>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="alta">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="classrooms" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" tabindex="-1" id="editarModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar Aula</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <h5>Datos del Aula {{ itemTarget.id }}</h5>
                  <form id="editarForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="class_code">Código del aula</label>
                        <input :value="itemTarget.class_code" type="text" name="class_code" id="class_code_editar" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="class_name">Nombre del aula</label>
                        <input :value="itemTarget.class_name" type="text" name="class_name" id="class_name_editar" class="form-control" required />
                      </div>
                      <div class="form-group col-3">
                        <label for="type">Tipo de aula</label>
                        <input :value="itemTarget.type" type="text" name="type" id="type_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="meters">Metros cuadrados del aula</label>
                        <input :value="itemTarget.meters" type="text" name="meters" id="meters_editar" class="form-control" />
                      </div>
                      <div class="form-group col-3">
                        <label for="center">Centro</label>
                        <multiselect v-model="selectedCenter" 
                        :options="centers"
                        :show-no-options="false"
                          tag-placeholder=""
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder=""
                          label="name"
                        
                        ></multiselect>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" id="editarButton"
                    @click="actualizar($event, itemTarget, 'boton')">
                    Actualizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" tabindex="-1" id="stockModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Stock</h5>
                  
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <button class="btn btn-success btnAñadirStock btn-block w-25" data-toggle="modal" data-target="#añadirStockModal">
                    Añadir stock
                  </button>
                  <!-- <li v-if="!itemTarget.classroom_stocks.length">Hola</li> -->
                  <div class="row">
                    <div :class="'stock'+stock['id']+' row col-6 p-4'" v-for="stock in itemTarget.classroom_stocks" :key="stock['id']">

                      <div class="row">
                        <div class="col-3">
                          {{ stock['stock']['name'] }}: &nbsp;
                        </div>
                        <div class="col-3">
                          <form :id="'cantidadEditarForm'+stock['id']">
                            <input :value="stock.stock_id" type="text" name="stock_id" :id="'stock_id_actualizar'+stock['id']" class="form-control d-none" required />
                            <input :value="stock.classroom_id" type="text" name="classroom_id" :id="'classroom_id_actualizar'+stock['id']" class="form-control d-none" required />
                            <input :value="stock.amount" type="text" name="amount" :id="'amount_editar'+stock['id']" class="form-control" />
                          </form>
                        </div>
                        <div class="col-6">
                          <button id="${item.id}"
                            class="btn btnEditarStock mr-2 bg-gradient-secondary" @click="actualizarStock($event, stock['id'], 'boton')"
                          >
                            <i class="fas fa-pen fa-fw"></i>
                          </button>
                          <button :id="stock['id']" class="btn btnBorrarStock mr-2 bg-gradient-secondary">
                            <i class="fas fa-times fa-fw"></i>
                          </button>  
                        </div>
                      </div>
                        
                         
                    </div>
                  </div>
                  <!-- <h5>Datos de Stock {{ itemTarget.classroom_stocks }}</h5> -->
                  
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cerrar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente una Aula almacenada.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminar(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarStockModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente un Stock almacenado en el aula.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminarStock(selectedClassroomStock)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="añadirStockModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Añadir stock
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <div class="col-6">
                      <form id="añadirStockForm">
                        <div class="form-row">
                          <div class="form-group col-6">
                            <label for="name">Nombre</label>
                            <input type="text" name="name" id="name_anadir" class="form-control" required />
                          </div>
                          <div class="form-group col-6">
                            <label for="amount">Cantidad</label>
                            <input type="text" name="amount" id="amount_anadir" class="form-control" required />
                          </div>
                          <div class="form-group col-6 d-none">
                            <label for="classroom_id">Aula</label>
                            <input :value="itemTarget.id" type="text" name="classroom_id" id="classroom_id_anadir" class="form-control" required />
                            <input :value="0" type="text" name="stock_id" id="stock_id_anadir" class="form-control" required />
                          </div>
                        </div>
                      </form>
                      <button type="button" class="btn btn-primary" @click="anadirStock">
                        Añadir nuevo stock
                      </button>
                    </div>
                    <div class="col-6">
                      <form id="añadirStockExistenteForm">
                        <div class="row">
                        <div class="form-group col-8">
                          <label for="stock">Stock</label>
                          <multiselect   v-model="this.selectedStock" 
                          :options="this.stocks"
                          :show-no-options="false"
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder=""
                          label="name"
                          required
                        
                        ></multiselect>
                        </div>
                        <div class="form-group col-4">
                            <label for="amount">Cantidad</label>
                            <input type="text" name="amount" id="amount_anadir" class="form-control" required />
                          </div>
                        </div>
                        <div class="form-group col-6 d-none">
                            <label for="classroom_id">Aula</label>
                            <input :value="itemTarget.id" type="text" name="classroom_id" id="classroom_id_anadir" class="form-control" required />
                            <input :value="0" type="text" name="stock_id" id="stock_id_anadir" class="form-control" required />
                          </div>
                      </form>
                      <button type="button" class="btn btn-primary" @click="anadirStockExistente">
                        Añadir stock existente
                      </button>
                    </div>
                  </div>
            
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
  import Multiselect from 'vue-multiselect'
  export default {
    components: { Multiselect },
    data() {
      return {
        classrooms: [],
        classroom_stocks: [],
        selectedCenter: null,
        selectedClassroomStock: null,
        selectedStock: null,
        centers: [],
        stocks: [],
        itemTarget: {
          id: null,
          class_code: null,
          class_name: null,
          type: null,
          meters: null,
          center_id: null,
        },
        cabecerasTabla: [{
            public: "Código del aula",
            private: "class_code",
            column: 1
          },
          {
            public: "Nombre del aula",
            private: "class_name",
            column: 2
          },
          {
            public: "Tipo de aula",
            private: "type",
            column: 3
          },
          {
            public: "Metros cuadrados del aula",
            private: "meters",
            column: 4
          },
          {
            public: "Centro",
            private: "center_id",
            column: 5
          }
        ]
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          if (this.selectedCenter) campos["center_id"] = this.selectedCenter.id
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/classrooms/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async actualizarStock(e, stockId, accion) {
        if (e.code == "Enter" || accion == "boton") {
          let cantidadEditarForm = $(
            "#cantidadEditarForm"+stockId
          ).serializeArray();
          let amountValue = "";
          let campos = {};
          cantidadEditarForm.forEach((campo) => {
            if (campo.name != 'stock_id' && campo.name != 'classroom_id') amountValue = campo.value;
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/classroom_stocks/${stockId}`,
            campos
          );
          $("#cantidadEditarForm"+stockId+" #amount_editar"+stockId).val(amountValue);
        }
      },
      async eliminar(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/classrooms/${id}`);
          this.fillTable()
          $("#confirmarEliminarModal").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async eliminarStock(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/classroom_stocks/${id}`);
          this.fillTable()
          $("#confirmarEliminarStockModal").modal("hide");
          $(".stock"+id).hide();
        } catch (err) {
          window.alert(err);
        }
      },
      async alta() {
        const altaForm = $("#altaForm").serializeArray();
        let campos = {};
        altaForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        if (this.selectedCenter) campos["center_id"] = this.selectedCenter.id
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/classrooms`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
      },
      async anadirStock() {
        const añadirStockForm = $("#añadirStockForm").serializeArray();
        let campos = {};
        añadirStockForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/classroom_stocks`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
        $("#stockModal").modal("hide");
        $("#añadirStockModal").modal("hide");
      },
      async anadirStockExistente() {
        const añadirStockExistenteForm = $("#añadirStockExistenteForm").serializeArray();
        let campos = {};
        añadirStockExistenteForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        if (this.selectedStock) campos["stock_id"] = this.selectedStock.id
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/classroom_stocks`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
        $("#stockModal").modal("hide");
        $("#añadirStockModal").modal("hide");
      },
      async launchModal(id, tipo) {

        if(tipo != "anadirStock") {
          this.itemTarget = this.classrooms.find(x => x.id == id)
          this.selectedCenter = this.itemTarget.center;
        }

        

        if (tipo == "anadirStock") $("#stockModal").modal("show");
        if (tipo == "editar") $("#editarModal").modal("show");
        if (tipo == "eliminar") $("#confirmarEliminarModal").modal("show");
        if (tipo == "stock") $("#stockModal").modal("show");


      },
      async launchModalStock(id, tipo) {
        // await this.getStocks()
       this.selectedClassroomStock= id
        if (tipo == "eliminarStock") $("#confirmarEliminarStockModal").modal("show");

      },
      async getData() {
        await this.getClassrooms()
        this.getCenters()
        this.getStocks()
      },
      async getClassrooms() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/classrooms`)
        this.classrooms = response.data.data
        this.classrooms.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                      <button id="${item.id}"
                        class="btn btnStock bg-gradient-success"
                      >
                        <i class="fas fa-box fa-fw"></i>
                      </button>        
                  </div>`
        });
      },
      async getCenters() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/centers`)
        this.centers = response.data.data
      },
      async getStocks() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/stocks`)
        this.stocks = response.data.data
      },
      inicializateDataTable() {
        $(function () {
          $("#classrooms .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let tabla = $("#classrooms").DataTable({
            paging: true,
            columns: [
              {
                data: "class_code",
              },
              {
                data: "class_name",
              },
              {
                data: "type",
              },
              {
                data: "meters",
              },
              {
                data: "center.name",
              },
              {
                data: "accion",
              }
            ],
            columnDefs: [{
              orderable: false,
              targets: [5],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#classrooms thead").on("keyup", ".column_search", function () {
            tabla.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#classrooms").hasClass("collapsed")) {
            $("#classrooms .topnav").addClass("d-none");
          } else {
            $("#classrooms .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#classrooms").hasClass("collapsed")) {
              $("#classrooms .topnav").addClass("d-none");
            } else {
              $("#classrooms .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#classrooms").DataTable().clear();
        $("#classrooms").DataTable().rows.add(this.classrooms);
        $("#classrooms").DataTable().draw();

        $("#classrooms").off("click", ".btnBorrar");
        $("#classrooms").off("click", ".btnEditar");
        $("#stockModal").off("click", ".btnBorrarStock");


        $("#classrooms").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "eliminar")
        });
        $("#classrooms").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "editar")
        });
        $("#stockModal").on("click", ".btnAñadirStock", function () {
          esto.launchModal(null, "anadirStock")
        });
        $("#classrooms").on("click", ".btnStock", function () {
          esto.launchModal($(this).attr('id'), "stock")
        });
        $("#stockModal").on("click", ".btnBorrarStock", function () {
          esto.launchModalStock($(this).attr('id'), "eliminarStock")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable()

    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.multiselect__option--highlight {
  background-color: #0d6efd !important;
}
</style>