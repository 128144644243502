<template>
  <div class="row" id="top">
    <div class="col-12">
      <div class="card mt-3">
        <div class="card-header">
          <div class="row">
            <div class="col">
              <h2>Trabajadores</h2>
            </div>
            <div class="col-md-2 mt-2">
              <button class="btn btn-light btn-block" data-toggle="modal" data-target="#altaModal">
                Dar de alta
              </button>
            </div>
          </div>

          <div class="modal" id="altaModal" tabindex="-1">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Dar de alta</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <h5 v-if="this.$route.params.type === 'students'">Datos del Alumno</h5>
                  <h5 v-else-if="this.$route.params.type === 'teachers'">Datos del Docente</h5>
                  <form id="altaForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input type="text" name="name" id="name" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="surname">Nombre de usuario</label>
                        <input type="text" name="username" id="surname" class="form-control" required />
                      </div>
                      <div class="form-group col-4">
                        <label for="email">Email</label>
                        <input type="email" name="email" id="email" class="form-control" />
                      </div>
                      <div class="form-group col-4">
                        <label for="password">Contraseña</label>
                        <input type="text" name="password" id="password" class="form-control" />
                      </div>
                      <div class="form-group col-4">
                        <label for="center_id">Centro</label>
                        <multiselect v-model="selectedCenter" 
                        :options="centers"
                        :show-no-options="false"
                          tag-placeholder=""
                          :hide-selected="false"
                          :show-no-results="false"
                          selectedLabel="Seleccionado"
                          selectLabel="Seleccionar"
                          deselectLabel=""
                          :clear-on-select="false" 
                          :close-on-select="true"
                          :searchable="true"
                          :allowEmpty="false"
                          track-by="id" 
                          placeholder=""
                          label="name"
                        
                        ></multiselect>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="alta">
                    Dar de alta
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table id="users" class="table table-bordered table-striped">
            <thead>
              <tr class="topnav">
                <th class="topnav-th px-2" v-for="cabecera in cabecerasTabla" :key="cabecera.column"></th>
                <th>Acción</th>
              </tr>
              <tr>
                <th v-for="cabecera in cabecerasTabla" :key="cabecera.column">
                  {{ cabecera.public }}
                </th>
                <th>
                </th>
              </tr>
            </thead>
          </table>
          <div class="modal" tabindex="-1" id="editarModal" @keyup="actualizar($event, itemTarget, 'tecla')">
            <div class="modal-dialog modal-xl">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Editar Aula</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

                <div class="modal-body">
                  <h5>Datos de {{ itemTarget.name }} {{ itemTarget.surname }}</h5>
                  <form id="editarForm">
                    <div class="form-row">
                      <div class="form-group col-6">
                        <label for="name">Nombre</label>
                        <input :value="itemTarget.name" type="text" name="name" id="name_edit" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="username">Nombre de usuario</label>
                        <input :value="itemTarget.username" type="text" name="username" id="username_edit" class="form-control" required />
                      </div>
                      <div class="form-group col-6">
                        <label for="email">Email</label>
                        <input :value="itemTarget.email" type="email" name="email" id="email" class="form-control" />
                      </div>
                      <div class="form-group col-6">
                        <label for="password">Contraseña</label>
                        <input :value="itemTarget.password" type="text" name="password" id="password_edit" class="form-control" />
                      </div>
                    </div>
                  </form>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" id="editarButton"
                    @click="actualizar($event, itemTarget, 'boton')">
                    Actualizar
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="confirmarEliminarModal" tabindex="-1">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">
                    Confirmación de baja
                  </h5>
                  <button type="button" class="close" data-dismiss="modal">
                    <span>&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>
                    Estás a punto de borrar de forma permanente una Aula almacenada.
                    <br />
                    ¿Seguro que lo desea dar de baja?
                  </p>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-danger" @click="eliminar(itemTarget.id)">
                    Dar de baja
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const $ = require("jquery");
  import axios from "axios";
  import Multiselect from 'vue-multiselect';
  export default {
    components: {
      Multiselect
    },
    data() {
      return {
        type: null,
        users: [],
        centers: [],
        selectedCenter: null,
        itemTarget: {
          id: null,
          name: null,
          username: null,
          email: null,
          password: null,
        },
        cabecerasTabla: [{
            public: "Nombre",
            private: "name",
            column: 1
          },
          {
            public: "Nombre de usuario",
            private: "username",
            column: 2
          },
          {
            public: "Email",
            private: "email",
            column: 3
          },
          {
            public: "Rol",
            private: "role",
            column: 4
          },
        ]
      }
    },
    methods: {
      async actualizar(e, item, accion) {
        if (e.code == "Enter" || accion == "boton") {
          const editarForm = $(
            "#editarForm"
          ).serializeArray();
          let campos = {};
          editarForm.forEach((campo) => {
            if (campo.value) {
              campos[campo.name] = campo.value;
            }
          });
          await axios.put(
            `${process.env.VUE_APP_API_URL}/api/users/${item.id}`,
            campos
          );
          this.fillTable()
          $("#editarModal").modal("hide");
        }
      },
      async eliminar(id) {
        try {
          await axios.delete(`${process.env.VUE_APP_API_URL}/api/users/${id}`);
          this.fillTable()
          $("#confirmarEliminarModal").modal("hide");
        } catch (err) {
          window.alert(err);
        }
      },
      async alta() {
        const altaForm = $("#altaForm").serializeArray();
        let campos = {};
        altaForm.forEach((campo) => {
          if (campo.value) {
            campos[campo.name] = campo.value;
          }
        });
        if (this.selectedCenter) campos["center_id"] = this.selectedCenter.id
        await axios.post(
          `${process.env.VUE_APP_API_URL}/api/users`,
          campos
        );
        this.fillTable()
        $("#altaModal").modal("hide");
      },
      async launchModal(id, tipo) {
        this.itemTarget = this.users.find(x => x.id == id)

        if (tipo == "editar") $("#editarModal").modal("show");
        if (tipo == "eliminar") $("#confirmarEliminarModal").modal("show");
      },
      async getData() {
        await this.getUsers()
        this.getCenters()
      },
      async getUsers() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`)
        this.users = response.data.data
        this.users.forEach(item => {
          item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`
        });
      },
      async getCenters() {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/centers`)
        this.centers = response.data.data
      },
      inicializateDataTable() {
        $(function () {
          $("#users .topnav th.topnav-th").each(function () {
            $(this).html(
              '<input type="text" class="column_search form-control" />'
            );
          });
          let tabla = $("#users").DataTable({
            paging: true,
            columns: [{
                data: "name",
              },
              {
                data: "username",
              },
              {
                data: "email",
              },
              {
                data: "role",
              },
              {
                data: "accion",
              },
            ],
            columnDefs: [{
              orderable: false,
              targets: [4],
            }, ],
            searching: true,
            language: {
              search: "Búsqueda global: ",
              zeroRecords: "",
              lengthMenu: "Mostrar _MENU_ registros",
              paginate: {
                "first": "Primero",
                "last": "Último",
                "next": "Siguiente",
                "previous": "Anterior"
              }
            },
            fnInfoCallback: function (
              oSettings,
              iStart,
              iEnd,
              iMax,
              iTotal,
              sPre
            ) {
              return "Mostrando: " + iTotal + " de " + iMax;
            },
            dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
            ordering: true,
            info: true,
            autoWidth: false,
            responsive: true,
          });
          $("#users thead").on("keyup", ".column_search", function () {
            tabla.column($(this).parent().index()).search(this.value).draw();
          });
          if ($("#users").hasClass("collapsed")) {
            $("#users .topnav").addClass("d-none");
          } else {
            $("#users .topnav").removeClass("d-none");
          }
          window.addEventListener("resize", () => {
            if ($("#users").hasClass("collapsed")) {
              $("#users .topnav").addClass("d-none");
            } else {
              $("#users .topnav").removeClass("d-none");
            }
          });
        });
      },
      async fillTable() {
        let esto = this
        await this.getData();
        $("#users").DataTable().clear();
        $("#users").DataTable().rows.add(this.users);
        $("#users").DataTable().draw();

        $("#users").off("click", ".btnBorrar");
        $("#users").off("click", ".btnEditar");

        $("#users").on("click", ".btnBorrar", function () {
          esto.launchModal($(this).attr('id'), "eliminar")
        });
        $("#users").on("click", ".btnEditar", function () {
          esto.launchModal($(this).attr('id'), "editar")
        });
      },
    },
    async created() {
      this.inicializateDataTable();
      this.fillTable();
    },
  };
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
  .multiselect__option--highlight {
    background-color: #0d6efd !important;
  }
</style>