<template>
    <div class="row" id="top">
        <div class="col-12">
            <div class="card mt-3">

                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h2>Sectores</h2>
                        </div>
                        <div class="col-md-4 mt-2">
                            <div class="row">
                                <div class="col">
                                    <input type="checkbox" v-model="this.switch" id="switchSectores"
                                        data-onlabel="Activos" data-offlabel="Inactivos">
                                </div>
                                <div class="col">
                                    <button class="btn btn-light btn-block" data-toggle="modal"
                                        data-target="#altaModal">
                                        Dar de alta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="altaModal" tabindex="-1">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Dar de alta</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5>Datos del Sector</h5>
                                <form id="altaFormSector">
                                    <div class="form-row">
                                        <div class="form-group col-6">
                                            <label for="name">Nombre</label>
                                            <input type="text" name="name" id="name" class="form-control" required />
                                        </div>
                                        <div class="form-group col-6">
                                            <label for="record">Código de expediente</label>
                                            <input type="record" name="record" id="record" class="form-control" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-primary" @click="altaSector">
                                    Dar de alta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" tabindex="-1" id="editarModalSector"
                    @keyup="actualizarSector($event, itemTarget, 'tecla')">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Editar Sector</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <h5>Datos de la Acción Formativa {{ itemTarget.id }}</h5>
                                <form id="editarForm">
                                    <div class="form-row">
                                        <div class="form-group col-6">
                                            <label for="name">Nombre</label>
                                            <input :value="itemTarget.name" type="text" name="name" id="name_edit"
                                                class="form-control" required />
                                        </div>
                                        <div class="form-group col-4">
                                            <label for="record">Código de expediente</label>
                                            <input :value="itemTarget.record" type="record" name="record"
                                                id="record_edit" class="form-control" />
                                        </div>
                                        <div class="form-group col-2">
                                            <label for="active">Estado</label>
                                            <br>
                                            <input type="checkbox" value="1" id="switchSector" data-onlabel="Activo"
                                                name="active" data-offlabel="Inactivo">
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-primary" id="editarButton"
                                    @click="actualizarSector($event, itemTarget, 'boton')">
                                    Actualizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="confirmarEliminarModal" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    Confirmación de baja
                                </h5>
                                <button type="button" class="close" data-dismiss="modal">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>
                                    Estás a punto de borrar de forma permanente un Registro almacenado.
                                    <br />
                                    ¿Seguro que lo desea dar de baja?
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-danger" @click="eliminarSector(itemTarget.id)">
                                    Dar de baja
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <table id="sectors" class="table table-bordered table-striped">
                        <thead>
                            <tr>
                                <th v-for="cabecera in cabecerasSectores" :key="cabecera.column">
                                    {{ cabecera.public }}
                                </th>
                                <th>
                                    Acción
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>

            <div class="card mt-3">

                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h2>Cursos</h2>
                        </div>
                        <div class="col-md-4 mt-2">
                            <div class="row">
                                <div class="col">
                                    <multiselect v-model="this.selectedSwitchSector"
                                        :options="sectors"
                                        :show-no-options="false" 
                                        tag-placeholder="" 
                                        :hide-selected="false"
                                        :show-no-results="false" 
                                        selectedLabel="Seleccionado" 
                                        selectLabel="Seleccionar"
                                        deselectLabel="Quitar" 
                                        :clear-on-select="false" 
                                        :close-on-select="true"
                                        :searchable="true" 
                                        :allowEmpty="true" 
                                        track-by="id" 
                                        label="name"
                                        placeholder="Sectores">
                                    </multiselect>
                                </div>
                                <div class="col">
                                    <button class="btn btn-light btn-block" data-toggle="modal"
                                        data-target="#altaModalCurso">
                                        Dar de alta
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="altaModalCurso" tabindex="-1">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Dar de alta</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <h5>Datos del Curso</h5>
                                <form id="altaFormCurso">
                                    <div class="form-row">
                                        <div class="col-xl-6 form-group">
                                            <label>Código del curso</label>
                                            <input type="text" class="form-control" id="course_code" name="course_code"
                                                placeholder="">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Añadir curso existente</label>
                                            <multiselect v-model="this.selectedCourse" :options="courses"
                                                :show-no-options="false" tag-placeholder="" :hide-selected="false"
                                                :show-no-results="false" selectedLabel="Seleccionado"
                                                selectLabel="Seleccionar" deselectLabel="" :clear-on-select="false"
                                                :close-on-select="true" :searchable="true" :allowEmpty="false"
                                                track-by="id" label="name" placeholder=""></multiselect>
                                        </div>
                                        <hr class="w-100">
                                        <div class="col-xl-6 form-group">
                                            <label>Tipo de curso</label>
                                            <select class="form-control" name="course_type">
                                                <option value="" selected disabled>Selecciona</option>
                                                <option value="0">Presencial</option>
                                                <option value="1">Teleformación</option>
                                                <option value="2">Mixta</option>
                                                <option value="3">BiModal</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Dirigido a</label>
                                            <select class="form-control" name="receivers">
                                                <option value="" selected disabled>Selecciona</option>
                                                <option value="0">Desempleados</option>
                                                <option value="1">Trabajadores</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Prioritario</label>
                                            <select class="form-control" name="promoted">
                                                <option value="0">No</option>
                                                <option value="1">Sí</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Publicado</label>
                                            <select class="form-control" name="visibility">
                                                <option value="0">Oculto</option>
                                                <option value="1">Mostrar</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Horario</label>
                                            <input type="text" class="form-control" id="schedule" name="schedule"
                                                placeholder="Insertar horario">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Sector</label>
                                            <multiselect v-model="this.selectedSector" :options="sectors"
                                                :show-no-options="false" tag-placeholder="" :hide-selected="false"
                                                :show-no-results="false" selectedLabel="Seleccionado"
                                                selectLabel="Seleccionar" deselectLabel="" :clear-on-select="false"
                                                :close-on-select="true" :searchable="true" :allowEmpty="false"
                                                track-by="id" label="name" placeholder=""></multiselect>

                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Aula</label>
                                            <multiselect v-model="this.selectedClassroom" :options="classrooms"
                                                :show-no-options="false" tag-placeholder="" :hide-selected="false"
                                                :show-no-results="false" selectedLabel="Seleccionado"
                                                selectLabel="Seleccionar" deselectLabel="" :clear-on-select="false"
                                                :close-on-select="true" :searchable="true" :allowEmpty="false"
                                                track-by="id" label="class_name" placeholder=""></multiselect>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="islamd">Isla</label>
                                            <multiselect v-model="selectedIsland" 
                                                :options="islands" 
                                                :show-no-options="false"
                                                tag-placeholder="" 
                                                :hide-selected="false" 
                                                :show-no-results="false"
                                                selectedLabel="Seleccionado" 
                                                selectLabel="Seleccionar" 
                                                deselectLabel=""
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :searchable="true" 
                                                :allowEmpty="false"
                                                track-by="id" 
                                                placeholder="" 
                                                label="name">
                                            </multiselect>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="town">Municipio</label>
                                            <select class="form-control" name="town_id">
                                                <option value="" selected disabled>Selecciona</option>
                                                <option v-for="town in filteredTowns" v-bind:value="town.id">{{town.name}}</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Fecha de inicio</label>
                                            <input type="date" class="form-control" id="start_date"
                                                name="start_date" placeholder="Insertar fecha de inicio">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Fecha de final</label>
                                            <input type="date" class="form-control" id="end_date"
                                                name="end_date" placeholder="Insertar fecha de final">
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-primary" @click="altaRelacionCursos">
                                    Dar de alta
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" tabindex="-1" id="editarModalRelacionCurso"
                    @keyup="actualizarRelacionCurso($event, itemTarget, 'tecla')">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Editar Acción Formativa</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>

                            <div class="modal-body">
                                <h5>Datos de la acción formativa: {{ itemTarget.course_code }} {{ itemTargetCourse.name }}</h5>
                                <form id="editarCourseForm">
                                    <div class="form-row">
                                        <div class="col-xl-6 form-group">
                                            <label>Código del curso</label>
                                            <input :value="itemTarget.course_code" type="text" class="form-control" id="course_code_edit" name="course_code"
                                                placeholder="">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Añadir curso existente</label>
                                            <multiselect v-model="this.selectedCourse" 
                                                :options="courses" 
                                                :show-no-options="false"
                                                tag-placeholder="" 
                                                :hide-selected="false" 
                                                :show-no-results="false"
                                                selectedLabel="Seleccionado" 
                                                selectLabel="Seleccionar" 
                                                deselectLabel=""
                                                :clear-on-select="false" 
                                                :close-on-select="true" 
                                                :searchable="true" 
                                                :allowEmpty="false"
                                                track-by="id" 
                                                placeholder="" 
                                                label="name">
                                                </multiselect>
                                        </div>
                                        <hr class="w-100">
                                        <div class="col-xl-6 form-group">
                                            <label>Tipo de curso</label>
                                            <select v-model="itemTarget.course_type" class="form-control" name="course_type">
                                                <option value="" selected disabled>Selecciona</option>
                                                <option value="0">Presencial</option>
                                                <option value="1">Teleformación</option>
                                                <option value="2">Mixta</option>
                                                <option value="3">BiModal</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Dirigido a</label>
                                            <select v-model="itemTarget.receivers" class="form-control" name="receivers">
                                                <option value="" selected disabled>Selecciona</option>
                                                <option value="0">Desempleados</option>
                                                <option value="1">Trabajadores</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">

                                            <label>Prioritario</label>
                                            <select v-model="itemTarget.promoted" class="form-control" name="promoted">
                                                <option value="0">No</option>
                                                <option value="1">Sí</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">

                                            <label>Publicado</label>
                                            <select v-model="itemTarget.visibility" class="form-control" name="visibility">
                                                <option value="0">Oculto</option>
                                                <option value="1">Mostrar</option>
                                            </select>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Horario</label>
                                            <input :value="itemTarget.schedule" type="text" class="form-control" id="schedule_edit" name="schedule"
                                                placeholder="Insertar horario">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Sector</label>
                                            <multiselect v-model="this.selectedSector" :options="sectors"
                                                :show-no-options="false" tag-placeholder="" :hide-selected="false"
                                                :show-no-results="false" selectedLabel="Seleccionado"
                                                selectLabel="Seleccionar" deselectLabel="" :clear-on-select="false"
                                                :close-on-select="true" :searchable="true" :allowEmpty="false"
                                                track-by="id" label="name" placeholder=""></multiselect>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="">Aula</label>
                                            <multiselect v-model="this.selectedClassroom" :options="classrooms"
                                                :show-no-options="false" tag-placeholder="" :hide-selected="false"
                                                :show-no-results="false" selectedLabel="Seleccionado"
                                                selectLabel="Seleccionar" deselectLabel="" :clear-on-select="false"
                                                :close-on-select="true" :searchable="true" :allowEmpty="false"
                                                track-by="id" label="class_name" placeholder=""></multiselect>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="islamd">Isla</label>
                                            <multiselect v-model="this.selectedIsland" 
                                            :options="islands" 
                                            :show-no-options="false"
                                            tag-placeholder="" 
                                            :hide-selected="false" 
                                            :show-no-results="false"
                                            selectedLabel="Seleccionado" 
                                            selectLabel="Seleccionar" 
                                            deselectLabel=""
                                            :clear-on-select="false" 
                                            :close-on-select="true" 
                                            :searchable="true" 
                                            :allowEmpty="false"
                                            track-by="id" 
                                            placeholder="" 
                                            label="name">
                                            </multiselect>
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label for="town">Municipio</label>
                                            <select class="form-control" name="town_id">
                                                <option v-for="town in filteredTowns" :value="town.id" :selected="town.id == itemTarget.town_id">{{town.name}}</option>
                                            </select>
                                        </div>
                                        
                                        <div class="col-xl-6 form-group">
                                            <label>Fecha de inicio</label>
                                            <input :value="itemTarget.start_date" type="date" class="form-control" id="start_date_edit"
                                                name="fecha_inicio" placeholder="Insertar fecha de inicio">
                                        </div>
                                        <div class="col-xl-6 form-group">
                                            <label>Fecha de final</label>
                                            <input :value="itemTarget.end_date" type="date" class="form-control" id="end_date_edit"
                                                name="end_date" placeholder="Insertar fecha de final">
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-primary" id="editarButton"
                                    @click="actualizarRelacionCurso($event, itemTarget, 'boton')">
                                    Actualizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal" id="confirmarEliminarRelacionCursoModal" tabindex="-1">
                    <div class="modal-dialog">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">
                                    Confirmación de baja
                                </h5>
                                <button type="button" class="close" data-dismiss="modal">
                                    <span>&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <p>
                                    Estás a punto de borrar de forma permanente un Registro almacenado.
                                    <br />
                                    ¿Seguro que lo desea dar de baja?
                                </p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-dismiss="modal">
                                    Cancelar
                                </button>
                                <button type="button" class="btn btn-danger" @click="eliminarRelacionCurso(itemTarget.id)">
                                    Dar de baja
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-body">
                    <table id="courses" class="table table-bordered table-striped">
                        <thead>
                            <tr class="topnav">
                                <th class="topnav-th px-2" v-for="cabecera in cabecerasCursos" :key="cabecera.column">
                                </th>
                                <th>Acción</th>
                            </tr>
                            <tr>
                                <th v-for="cabecera in cabecerasCursos" :key="cabecera.column">
                                    {{ cabecera.public }}
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const $ = require("jquery");
    import axios from "axios";
    import router from '../../router/index.js'
    import Multiselect from 'vue-multiselect'
    export default {
        components: {
            Multiselect
        },
        data() {
            return {
                userId: this.$store.state.userId,
                switch: true,
                courses: [],
                selectedCourse: null,
                relationCourses: [],
                sectors: [],
                center: null,
                selectedSwitchSector: null,
                selectedSector: null,
                islands: [],
                selectedIsland: null,
                classrooms: [],
                selectedClassroom: null,
                towns: [],
                selectedTown: null,
                itemTarget: {
                    id: null
                },
                itemTargetCourse: {
                    id: null
                },
                cabecerasSectores: [{
                        public: "Nombre",
                        private: "name",
                        column: 0
                    },
                    {
                        public: "Código de expediente",
                        private: "record",
                        column: 1
                    }
                ],
                cabecerasCursos: [{
                        public: "Nombre",
                        private: "name",
                        column: 0
                    },
                    {
                        public: "Especialidad",
                        private: "speciality_code",
                        column: 1
                    },
                    {
                        public: "Nivel",
                        private: "level",
                        column: 2
                    },
                    {
                        public: "Horas",
                        private: "hours",
                        column: 3
                    },
                    {
                        public: "Familia",
                        private: "professional_family",
                        column: 4
                    },
                ],

            }
        },
        computed: {
            filteredTowns: function() {
                if(this.selectedIsland == null) return this.towns;
                return this.towns.filter(function(el) {
                    return el.island_id === this.selectedIsland.id;
                }, this);
            }
        },
        methods: {
            async getData() {
                await this.getSectors()
                this.retrieveCourses()
                await this.getCourses()
                this.getClassrooms()
                this.getIslands()
                this.getTowns()
            },
            async getSectors() {
                let entityId = this.$route.params.entity
                let entityUser = 'entity_id='+entityId+'&user_id='+this.userId
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/sectors?${entityUser}`)
                this.center = response.data.data.center
                this.sectors = response.data.data.sectors
                this.sectors.forEach(item => {
                    item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`

                });
            },
            async getIslands() {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/islands`)
                this.islands = response.data.data
            },
            async getClassrooms() {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/classrooms`)
                this.classrooms = response.data.data
            },
            async getTowns() {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/towns`)
                this.towns = response.data.data
            },
            async altaSector() {
                const altaForm = $("#altaFormSector").serializeArray();
                let campos = {};
                altaForm.forEach((campo) => {
                    if (campo.value) {
                        campos[campo.name] = campo.value;
                    }
                });
                
                campos["entity_id"] = this.$route.params.entity
                campos["active"] = 1
                campos["user_id"] = this.userId;

                await axios.post(
                    `${process.env.VUE_APP_API_URL}/api/sectors`,
                    campos
                );
                this.fillTable()
                $("#altaModal").modal("hide");
            },
            async actualizarSector(e, item, accion) {
                if (e.code == "Enter" || accion == "boton") {
                    const editarForm = $(
                        "#editarForm"
                    ).serializeArray();
                    let campos = {};
                    editarForm.forEach((campo) => {
                        if (campo.value) {
                            campos[campo.name] = campo.value;
                        }
                    });

                    if (!campos["active"]) campos["active"] = 0
                    campos["center_id"] = this.itemTarget.center_id
                    campos["entity_id"] = this.itemTarget.entity_id

                    await axios.put(
                        `${process.env.VUE_APP_API_URL}/api/sectors/${item.id}`,
                        campos
                    );
                    this.fillTable()
                    $("#editarModalSector").modal("hide");
                }
            },
            async eliminarSector(id) {
                try {
                    await axios.delete(`${process.env.VUE_APP_API_URL}/api/sectors/${id}`);
                    this.fillTable()
                    $("#confirmarEliminarModal").modal("hide");
                } catch (err) {
                    window.alert(err);
                }
            },
            retrieveCourses() {
                //relation_center_active_courses seria relation_center_courses si en la api usara la relacion standard
                this.relationCourses = this.sectors.flatMap(item => item.relation_center_active_courses);
                this.relationCourses.forEach(item => {
                    item.accion = `<div class=" d-flex justify-content-center">
                      <button id="${item.id}"
                        class="btn btnIr mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-plus-circle"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnEditar mr-2 bg-gradient-secondary"
                      >
                        <i class="fas fa-pen fa-fw"></i>
                      </button>
                      <button id="${item.id}"
                        class="btn btnBorrar bg-gradient-secondary"
                      >
                        <i class="fas fa-times fa-fw"></i>
                      </button>        
                  </div>`

                });
            },
            async getCourses() {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/courses`)
                this.courses = response.data.data
            },
            async altaRelacionCursos() {
                const altaForm = $("#altaFormCurso").serializeArray();
                let campos = {};
                altaForm.forEach((campo) => {
                    if (campo.value) {
                        campos[campo.name] = campo.value;
                    }
                });
                campos["deleted"] = 0
                if (this.selectedCourse) campos["course_id"] = this.selectedCourse.id
                if (this.selectedClassroom) campos["classroom_id"] = this.selectedClassroom.id
                if (this.selectedTown) campos["town_id"] = this.selectedTown.id
                if (this.selectedSector) campos["sector_id"] = this.selectedSector.id
                let response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/api/relation-center-courses`,
                    campos
                );
                this.fillTable()
                $("#altaModalCurso").modal("hide");
            },
            async actualizarRelacionCurso(e, item, accion) {
                if (e.code == "Enter" || accion == "boton") {
                    const editarForm = $(
                        "#editarCourseForm"
                    ).serializeArray();
                    let campos = {};
                    editarForm.forEach((campo) => {
                        if (campo.value) {
                            campos[campo.name] = campo.value;
                        }
                    });

                    await axios.put(
                        `${process.env.VUE_APP_API_URL}/api/relation-center-courses/${item.id}`,
                        campos
                    );
                    this.fillTable()
                    $("#editarModalRelacionCurso").modal("hide");
                }
            },
            async eliminarRelacionCurso(id) {
                try {
                    let deleter = id+','+this.userId;
                    await axios.delete(`${process.env.VUE_APP_API_URL}/api/relation-center-courses/${deleter}`);
                    this.fillTable()
                    $("#confirmarEliminarRelacionCursoModal").modal("hide");
                } catch (err) {
                    window.alert(err);
                }
            },
            async launchModal(id, tipo) {
                if(tipo == 'editarSector' || tipo == 'eliminarSector') this.itemTarget = this.sectors.find(x => x.id == id)
                if(tipo == 'editarRelacionCurso' || tipo == 'eliminarRelacionCurso') this.itemTarget = this.relationCourses.find(x => x.id == id)
                if (tipo == "editarSector") {
                    $("#editarModalSector").modal("show")
                    if (this.itemTarget.active) {
                        document.getElementById('switchSector').switchButton("on");
                    } else {
                        document.getElementById('switchSector').switchButton("off");
                    }
                };
                if (tipo == "eliminarSector") $("#confirmarEliminarModal").modal("show");
                if (tipo == "editarRelacionCurso") {
                    this.itemTargetCourse = this.courses.find(x => x.id == this.itemTarget.course_id)
                    this.selectedCourse = this.courses.find(x => x.id == this.itemTarget.course_id)
                    this.selectedSector = this.sectors.find(x => x.id == this.itemTarget.sector_id)
                    this.selectedTown = this.itemTarget.town_id;
                    let town = this.towns.find(x => x.id == this.selectedTown)
                    this.selectedIsland = this.islands.find(x => x.id == town.island_id)
                    this.selectedClassroom = this.classrooms.find(x => x.id == this.itemTarget.classroom_id)
                    $("#editarModalRelacionCurso").modal("show");
                }
                if (tipo == "eliminarRelacionCurso") $("#confirmarEliminarRelacionCursoModal").modal("show");
            },
            inicializateDataTableSectores() {
                let esto = this

                $(function () {
                    let tabla = $("#sectors").DataTable({
                        "initComplete": function (settings, json) {
                            $("#sectors").DataTable().column(3).search(esto.switch, true, false)
                                .draw();
                        },
                        paging: true,
                        columns: [{
                                data: "name",
                            },
                            {
                                data: "record",
                            },
                            {
                                data: "accion"
                            },
                            {
                                data: "active"
                            }
                        ],
                        columnDefs: [{
                                orderable: false,
                                targets: [2],
                            },
                            {
                                visible: false,
                                targets: [3],
                            },
                        ],
                        searching: true,
                        language: {
                            search: "Búsqueda global: ",
                            zeroRecords: "",
                            lengthMenu: "Mostrar _MENU_ registros",
                            paginate: {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            }
                        },
                        dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
                        ordering: true,
                        info: false,
                        autoWidth: false,
                        responsive: true,
                    });
                });
            },
            inicializateDataTableCursos() {
                $(function () {
                    $("#courses .topnav th.topnav-th").each(function () {
                        $(this).html(
                            '<input type="text" class="column_search form-control" />'
                        );
                    });
                    let tabla = $("#courses").DataTable({
                        paging: true,
                        columns: [
                            {
                                data: "course.name",
                            },
                            {
                                data: "course.specialty_code",
                            },
                            {
                                data: "course.level",
                            },
                            {
                                data: "course.hours",
                            },
                            {
                                data: "course.professional_family",
                            },
                            {
                                data: "accion"
                            },
                            {
                                data: "sector_id"
                            }
                        ],
                        columnDefs: [{
                                orderable: false,
                                target: 5,
                            },
                            {
                                visible: false,
                                target: 6,
                            }
                        ],
                        searching: true,
                        language: {
                            search: "Búsqueda global: ",
                            zeroRecords: "",
                            lengthMenu: "Mostrar _MENU_ registros",
                            paginate: {
                                "first": "Primero",
                                "last": "Último",
                                "next": "Siguiente",
                                "previous": "Anterior"
                            }
                        },
                        fnInfoCallback: function (
                            oSettings,
                            iStart,
                            iEnd,
                            iMax,
                            iTotal,
                            sPre
                        ) {
                            return "Mostrando: " + iTotal + " de " + iMax;
                        },
                        dom: '<"top"<"row"<"col"li><"col"><"col"f>>>rt<"bottom"p>',
                        ordering: true,
                        info: true,
                        autoWidth: false,
                        responsive: true,
                    });
                    $("#courses thead").on("keyup", ".column_search", function () {
                        tabla.column($(this).parent().index()).search(this.value).draw();
                    });
                    if ($("#courses").hasClass("collapsed")) {
                        $("#courses .topnav").addClass("d-none");
                    } else {
                        $("#courses .topnav").removeClass("d-none");
                    }
                    window.addEventListener("resize", () => {
                        if ($("#courses").hasClass("collapsed")) {
                            $("#courses .topnav").addClass("d-none");
                        } else {
                            $("#courses .topnav").removeClass("d-none");
                        }
                    });
                });
            },
            async fillTable() {
                let esto = this
                await this.getData();

                $("#sectors").DataTable().clear();
                $("#sectors").DataTable().rows.add(this.sectors);
                $("#sectors").DataTable().draw();

                $("#sectors").off("click", ".btnBorrar");
                $("#sectors").off("click", ".btnEditar");

                $("#sectors").on("click", ".btnBorrar", function () {
                    esto.launchModal($(this).attr('id'), "eliminarSector")
                });
                $("#sectors").on("click", ".btnEditar", function () {
                    esto.launchModal($(this).attr('id'), "editarSector")
                });

                $("#courses").DataTable().clear();
                $("#courses").DataTable().rows.add(this.relationCourses);
                $("#courses").DataTable().draw();

                $("#courses").off("click", ".btnBorrar");
                $("#courses").off("click", ".btnEditar");
                $("#courses").off("click", ".btnIr");

                $("#courses").on("click", ".btnBorrar", function () {
                    esto.launchModal($(this).attr('id'), "eliminarRelacionCurso")
                });
                $("#courses").on("click", ".btnEditar", function () {
                    esto.launchModal($(this).attr('id'), "editarRelacionCurso")
                });
                $("#courses").on("click", ".btnIr", function () {
                    let route = '/crm/formativeAction/'+$(this).attr('id');
                    router.push({ path: route })
                });
            }
        },
        watch: {
            selectedSwitchSector: function (selectedSwitchSector) {
                if (selectedSwitchSector) {
                    $("#courses").DataTable().column(6).search(selectedSwitchSector.id, true, false).draw();
                } else {
                    $("#courses").DataTable().column(6).search('', true, false).draw();
                }
            },

            switch: function (val) {
                $("#sectors").DataTable().column(3).search(val, true, false).draw()
            },
        },
        mounted() {
            document.getElementById('switchSectores').switchButton();
        },
        async created() {
            this.inicializateDataTableSectores();
            this.inicializateDataTableCursos();
            this.fillTable();

            this.$watch(
                () => this.$route.params,
                () => {
                    this.fillTable()
                }, {
                    immediate: true
                }
            )
        },
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
    .multiselect__option--highlight {
        background-color: #0d6efd !important;
    }
</style>